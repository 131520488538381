export default [
    {
        key: 'en',
        value: 'English'
    },
    {
        key: 'ja',
        value: '日本語'
    },
    {
        key: 'ko',
        value: '한국인'
    },
    {
        key: 'vi',
        value: 'Tiếng Việt'
    },
    {
        key: 'th',
        value: 'ภาษาไทย'
    },
    {
        key: 'zh-TW',
        value: '繁體中文'
    }
]