<template>
    <div class="h5-container">
        <div v-if="showModal && once" class="mask">
            <div class="modal animate__animated animate__zoomIn">
                <div class="modal-top">
                    <div class="modal-title">{{$t('Home.TcTitle')}}</div>
                    <i class="el-icon-close" @click="closeModal"></i>
                </div>
                <div v-html="tcInfo" class="modal-content"></div>
            </div>
        </div>
        <div class="header">
            <img v-if="token" class="account" src="@/assets/images/h5/index/account.png" @click="to('/h5/account')" />
            <div v-else class="login-text" @click="to('/h5/login')">{{$t('Login.Login')}}</div>
            <div class="page-title">BitVenus</div>
            <el-dropdown trigger="click" class="lang-box" @command="changeLang">
                <span class="el-dropdown-link">
                    {{curLang}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="(item,index) in langList" :key="index" :command="item.key">
                        {{item.value}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- <div class="top">
            <div class="top-content animate__animated animate__bounceInLeft">
                <div class="top-title1">{{$t('Home.TopTitle1')}}</div>
                <div class="top-title2">{{$t('Home.TopTitle2')}}</div>
                <div class="account-input">
                    <input v-model="account" class="form-input" :placeholder="$t('Home.EmailAddress')" />
                    <div class="start-btn" @click="toRegister">{{$t('Home.StartUsing')}}</div>
                </div>
            </div>
        </div> -->
        <div class="asset-box">
            <div class="title">
                <div class="label">{{$t('Assets.AvailableBalance')}}</div>
                <img v-if="isShow" class="icon" src="@/assets/images/h5/assets/eye.png" @click="isShow = !isShow" />
                <img v-else class="icon" src="@/assets/images/h5/assets/eye-lock.png" @click="isShow = !isShow" />
            </div>
            <div class="balance">
                <div class="num" :style="{ fontSize: isShow ? '2.5rem' : '3.5rem' }">{{isShow ? myCoinBalance : '*****'}}</div>
                <el-dropdown trigger="click" class="coin-list-box" @command="changeCoin">
                    <span class="el-dropdown-link">
                        {{myCoin}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item,index) in coinList" :key="index" :command="item">
                            {{item}}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="btns">
                <div class="btn1" @click="to('/h5/CurrencyTrading')">
                    <img class="icon" src="@/assets/images/h5/assets/trade.png" />
                    {{$t('Home.Trade')}}
                </div>
                <div class="btn2" @click="toDeposit">
                    <img class="icon" src="@/assets/images/h5/assets/deposit.png" />
                    {{$t('Assets.Deposit')}}
                </div>
            </div>
        </div>
        <div class="main">
            <div class="tab-list">
                <div v-for="(item,index) in tabList" :key="index" class="tab-item" @click="to(item.url,item.name)">
                    <img class="icon" :src="item.icon" />
                    <div class="name">{{item.name}}</div>
                </div>
            </div>
            <div class="banner-box">
                <el-carousel trigger="click" arrow="never" :interval="4000" :loop="false" :height="bannerHeight + 'px'">
                    <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                        <img class="banner-img" :src="item.pic_url" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="notice-box">
                <div class="notice">
                    <div class="left">
                        <img class="icon" src="@/assets/images/h5/index/notice.png" />
                        <div class="text">{{notice}}</div>
                    </div>
                    <div class="more" @click="to('/h5/announcement')">{{$t('Home.SeeMore')}}》</div>
                </div>
            </div>
            <el-table :data="productList" style="width: 100%">
                <el-table-column :label="$t('Home.Currency')">
                    <template slot-scope="scope">
                        <div class="coin-box">
                            <img class="coin-icon" :src="scope.row.pic" />
                            <span class="coin-name">{{scope.row.name}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('Trade.Price')">
                    <template slot-scope="scope">
                        <span :style="{ color: parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '#01BD8B' : '#FB474E' }">${{parseFloat(scope.row.price.close)}}</span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('Home.Change')">
                    <template slot-scope="scope">
                        <span :style="{ color: parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '#01BD8B' : '#FB474E' }">
                            {{parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '+' : ''}}{{((parseFloat(scope.row.price.close) - parseFloat(scope.row.price.open))/ parseFloat(scope.row.price.open) * 100).toFixed(2)}}%
                        </span>
                        <i :class="['arrow', parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? 'el-icon-caret-top' : 'el-icon-caret-bottom']"
                            :style="{ color: parseFloat(scope.row.price.close) >= parseFloat(scope.row.price.open) ? '#01BD8B' : '#FB474E' }"></i>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('Home.Operate')">
                    <template slot-scope="scope">
                        <div class="exchange-btn" @click="trade(scope.row)">{{$t('Home.Trade')}}</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <tabbar :activeId="0"></tabbar>
    </div>
</template>

<script>
    import lang_list from '@/utils/langList'
    export default {
        components: {
            tabbar: () => import('@/components/tabbar.vue')
        },
        data() {
            return {
                showModal: false,
                tcInfo: '',
                langList: lang_list,
                curLang: '',
                account: '',
                credit: [],
                isShow: false,
                coinList: ['BTC','ETH','USDT','USDC'],
                myCoin: 'BTC',
                bannerList: [],
                bannerWidth: 0,
                bannerHeight: 0,
				notice: '',
                tabList: [
                    {
                        name: this.$t('Assets.Deposit'),
                        icon: require('@/assets/images/h5/index/tab1.png'),
                        url: '/h5/assets/deposit'
                    },
                    {
                        name: this.$t('Module.Finance'),
                        icon: require('@/assets/images/h5/index/tab2.png'),
                        url: '/h5/finance'
                    },
                    {
                        name: this.$t('Home.FooterItems2')[4],
                        icon: require('@/assets/images/h5/index/tab3.png'),
                        url: 'msb_auth'
                    },
                    {
                        name: this.$t('Home.FooterItems1')[4],
                        icon: require('@/assets/images/h5/index/tab4.png'),
                        url: 'contact_us'
                    },
                    {
                        name: this.$t('Home.CustomerService'),
                        icon: require('@/assets/images/h5/index/tab5.png'),
                        url: '/h5/CustomerService'
                    }
                ]
            }
        },
        created() {
            this.getCurLang()
			this.getTcInfo()
			this.getNotice()
            this.getCredit()
            this.getBannerList()
            this.$store.dispatch('getProduct')
        },
		mounted() {
		    if (!this.$ws.socket) {
		        this.$ws.init()
		    }
            // 首次加载时,需要调用一次
            this.setSize()
            // 窗口大小发生改变时,调用一次
            window.onresize = () => {
                this.setSize()
            }
		},
		activated() {
		    if (!this.$ws.socket) {
		        this.$ws.init()
		    }
		},
        computed: {
            connect() {
                return this.$store.state.connect
            },
            
            once() {
                return this.$store.state.once
            },
            
            token() {
                return this.$store.state.token
            },
            
            productList() {
                return this.$store.state.productList
            },
            
            assetList() {
                let list = []
                list = JSON.parse(JSON.stringify(this.credit.map(item => {
                    if(item.type == 'USDT') {
                        item['frozen'] = this.frozen
                    } else {
                        item['frozen'] = '0.00000000'
                    }
                    
                    return item
                })))
                
                return list
            },
            
            myCoinBalance() {
                let selcet_coin = this.assetList.find(item => item.type == this.myCoin)
                return selcet_coin ? selcet_coin.num : 0
            }
        },
        watch: {
            connect(value) {
                let val = JSON.parse(value)
                if(val.status) {
                    this.$ws.sendMsg('{"type":"join","data":"ticker"}')
                }
            }
        },
        methods: {
            closeModal() {
                this.showModal = false
                this.$store.commit('setOnce',false)
            },
            
            changeLang(lang) {
                // console.log(lang)
                localStorage.setItem('lang', lang)
                location.reload()
            },
            
            getCurLang() {
                this.curLang = this.langList[0].value
                this.langList.forEach(item => {
                    if(item.key == localStorage.getItem('lang')) {
                        this.curLang = item.value
                    }
                })
            },
            
            getCredit() {
                this.$api.credit().then(res => {
                    this.credit = res.data
                })
            },
            
            getBannerList() {
                this.$api.banner({},300000).then(res => {
                    this.bannerList = res.data
                })
            },
            
            setSize() {
                // 获取Banner宽度
                this.bannerWidth = document.getElementsByClassName('banner-box')[0].clientWidth
                // 获取Banner高度
                this.bannerHeight = (2 / 5) * this.bannerWidth
            },
            
            changeCoin(coin) {
                this.myCoin = coin
            },
            
            toDeposit() {
                this.$router.push({
                    path: '/h5/assets/deposit',
                    query: {
                        coin_name: this.myCoin
                    }
                })
            },
            
            to(path,name) {
				if(path == 'msb_auth' || path == 'contact_us') {
					this.$router.push({
					    path: '/h5/article',
					    query: {
					        slug: path,
							name
					    }
					})
				} else {
					this.$router.push({
					    path
					})
				}
            },
            
            toRegister() {
                this.$router.push({
                    path: '/h5/register',
                    query: {
                        email: this.account
                    }
                })
            },
			
			getTcInfo() {
			    this.$api.setting_lang({
			        slug: 'tc_gg'
			    },300000).then(res => {
			        if(res.data) {
			            this.tcInfo = res.data
			            this.showModal = true
			        }
			    })
			},
			
			getNotice() {
			    this.$api.announcement({},300000).then(res => {
			        if(res.data[0]) {
			            this.notice = res.data[0].title
			        }
			    })
			},
            
            trade(row) {
                this.$store.commit('setCurCoin', row)
                this.to('/h5/CurrencyTrading')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        padding-bottom: 6rem;
    }
    
    .el-dropdown-link {
        white-space: nowrap;
    }
    
    .mask {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
        padding: 0 1.6rem;
        background-color: rgba(0,0,0,.4);
    
        .modal {
            width: 100%;
            box-sizing: border-box;
            padding: 2.2rem 2.4rem 3rem;
            border-radius: 8px;
            background: url(~@/assets/images/h5/index/tc-bg.png) no-repeat;
            background-size: 100% 100%;
            animation-duration: 800ms;
            
            .modal-top {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: 2.2rem;
                
                .modal-title {
                    max-width: 60%;
                    font-size: 1.7rem;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .el-icon-close {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    font-size: 1.9rem;
                    font-weight: bold;
                    color: #6D6F7E;
                    cursor: pointer;
                }
            }
    
            .modal-content {
                width: 100%;
                min-height: 8rem;
                max-height: 30rem;
                word-wrap: break-word;
                overflow: auto;
            }
        }
    }
    
    .header {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 40;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 5rem;
        box-sizing: border-box;
        padding: 0 1.2rem;
        background-color: #FFFFFF;
        
        .page-title {
            font-size: 1.8rem;
            font-weight: bold;
            color: #000000;
            white-space: nowrap;
        }
        
        .account {
            position: absolute;
            top: 50%;
            left: 1.2rem;
            transform: translateY(-50%);
            min-width: 2.4rem;
            min-width: 2.4rem;
            height: 2.4rem;
        }
        
        .login-text {
            position: absolute;
            top: 50%;
            left: 1.2rem;
            transform: translateY(-50%);
            font-size: 1.4rem;
            color: #6D6F7E;
            white-space: nowrap;
        }
        
        .lang-box {
            position: absolute;
            top: 50%;
            right: 1.2rem;
            transform: translateY(-50%);
        }
    }
    
    .top {
        width: 100%;
        box-sizing: border-box;
        padding: 2rem 1.2rem;
        background: url(~@/assets/images/h5/index/top-bg.png) no-repeat;
        background-size: 100% 100%;
        
        .top-content {
            width: calc(100% - 2.4rem);
            animation-delay: 200ms;
            animation-duration: 1000ms;
        
            .top-title1 {
                width: 60%;
                margin-bottom: 2rem;
                font-size: 2.2rem;
                font-weight: bold;
                color: transparent;
                word-wrap: break-word;
                background-image: linear-gradient(#14D5F1, #1B80FA);
                background-clip: text;
            }
        
            .top-title2 {
                width: 60%;
                margin-bottom: 2.8rem;
                font-size: 1.3rem;
                color: #30415E;
                word-wrap: break-word;
            }
        
            .account-input {
                display: flex;
                align-items: center;
                width: 100%;
        
                .form-input {
                    width: 100%;
                    height: 4rem;
                    box-sizing: border-box;
                    padding: 0 1.2rem;
                    border: 0;
                    border-radius: 2px;
                    outline: none;
                    font-size: 1.3rem;
                    color: #000000;
                    background-color: #FFFFFF;
                }
        
                .start-btn {
                    height: 4rem;
                    line-height: 4rem;
                    box-sizing: border-box;
                    padding: 0 1.6rem;
                    margin-left: 12px;
                    border-radius: 2px;
                    font-size: 1.3rem;
                    color: #FFFFFF;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #F08834;
                    cursor: pointer;
                }
            }
        }
    }
    
    .asset-box {
        width: 100%;
        box-sizing: border-box;
        padding: 2.4rem 1.2rem;
        
        .title {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 1.2rem;
            
            .label {
                max-width: 100%;
                font-size: 1.4rem;
                color: #000000;
                word-wrap: break-word;
            }
            
            .icon {
                min-width: 1.6rem;
                max-width: 1.6rem;
                height: 1.6rem;
                margin-left: .8rem;
            }
        }
        
        .balance {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 2.4rem;
            
            .num {
                max-width: 100%;
                font-weight: bold;
                color: #000000;
                word-break: break-all;
            }
            
            .coin-list-box {
                margin-left: .8rem;
            }
        }
        
        .btns {
            display: flex;
            justify-content: space-between;
            width: 100%;
            
            .btn1 {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 49%;
                height: 4rem;
                box-sizing: border-box;
                border-radius: 4rem;
                font-size: 1.4rem;
                color: #000000;
                white-space: nowrap;
                background-color: #F1F1F1;
                
                .icon {
                    min-width: 2.2rem;
                    max-width: 2.2rem;
                    height: 2.2rem;
                    margin-right: .6rem;
                }
            }
            
            .btn2 {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 49%;
                height: 4rem;
                box-sizing: border-box;
                border-radius: 4rem;
                font-size: 1.4rem;
                color: #FFFFFF;
                white-space: nowrap;
                background-color: #F08834;
                
                .icon {
                    min-width: 2.4rem;
                    max-width: 2.4rem;
                    height: 2.4rem;
                    margin-right: .6rem;
                }
            }
        }
    }
    
    .main {
        width: 100%;
        box-sizing: border-box;
        padding: 0 1.2rem 1.6rem;
        background-color: #FFFFFF;
        
        .tab-list {
            display: flex;
            justify-content: space-between;
            width: 100%;
            
            .tab-item {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                
                &:not(:last-child) {
                    margin-right: 1rem;
                }
                
                .icon {
                    min-width: 3rem;
                    max-width: 3rem;
                    height: 3rem;
                    margin-bottom: 6px;
                }
                
                .name {
                    max-width: 100%;
                    font-size: 1.3rem;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
            }
        }
        
        .banner-box {
            width: 100%;
            box-sizing: border-box;
            padding: 2rem 0;
            
            .banner-img {
                width: 100%;
                height: inherit;
            }
        }
        
        .notice-box {
            width: 100%;
            margin-bottom: 2.4rem;
        
            .notice {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 4.2rem;
                box-sizing: border-box;
                padding: 0 0.8rem;
                border-radius: 4px;
                background-color: #F5F6F9;
        
                .left {
                    display: flex;
                    align-items: center;
                    width: 100%;
        
                    .icon {
                        min-width: 2.2rem;
                        max-width: 2.2rem;
                        height: 2.2rem;
                        margin-right: 0.6rem;
                    }
        
                    .text {
                        width: calc(100% - 2rem);
                        font-size: 1.2rem;
                        color: #282D3B;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }
        
                .more {
                    margin-left: 2rem;
                    font-size: 1.2rem;
                    color: #F4A95A;
                    white-space: nowrap;
                    cursor: pointer;
                }
            }
        }
        
        /deep/ .el-table th.is-leaf {
            padding: 8px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 6px;
                font-size: 12px;
                font-weight: normal;
                color: #6D6F7E;
                text-align: center;
            }
        }
        
        /deep/ .el-table td {
            padding: 12px 0;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 6px;
                font-size: 13px;
                color: #000000;
                text-align: center;
            }
        }
        
        /deep/ .el-table__empty-block {
            min-height: 100px;
        
            .el-table__empty-text {
                line-height: 100px;
                font-size: 13px;
            }
        }
        
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
            background-color: #F3F5FF;
        }
        
        .coin-box {
            display: flex;
            align-items: center;
            width: 100%;
            
            .coin-icon {
                min-width: 2rem;
                max-width: 2rem;
                height: 2rem;
                margin-right: 4px;
            }
            
            .coin-name {
                font-weight: bold;
            }
        }
        
        .arrow {
            margin-left: 2px;
            font-size: 1.2rem;
        }
        
        .exchange-btn {
            height: 26px;
            line-height: 26px;
            box-sizing: border-box;
            padding: 0 12px;
            border-radius: 2px;
            font-size: 12px;
            color: #F08834;
            text-align: center;
            white-space: nowrap;
            background-color: rgba(240,136,52,.1);
            cursor: pointer;
        
            &:hover {
                color: #FFFFFF;
                background-color: #F08834;
            }
        }
    }
</style>
